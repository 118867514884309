<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="placeholder"
    style="width: 100%"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="$t('User not found')"
    :size="size"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d.uid">
      {{ getUserFullname(d) }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "User not found": "User not found"
  },
  "ja": {
    "User not found": "ユーザーが見つかりません"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { getFullname } from '@/utils/users';

export default {
  name: 'InputOperator',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      id: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (this.id !== nv && nv) {
          this.id = nv;
        }
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value) {
      const params = { name: value, infinite: true };

      if (this.role) {
        params.role = this.role;
      }

      return this.$store
        .dispatch('user/users', params)
        .then((res) => {
          this.data = res.list;

          return Promise.resolve(true);
        });
    },
    delaySearch: debounce(function (value) {
      this.handleSearch(value);
    }, 600),
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>
