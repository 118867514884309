export const TYPE = {
  STAY: 'stay',
  VACANCY: 'vacancy',
};

export const THRESHOLD = {
  ABOVE: 'above',
  BELOW: 'below',
};

export default {
  TYPE,
  THRESHOLD,
};
