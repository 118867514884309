{
  "en": {
    "Housekeeping Cycles": "Housekeeping Cycles",
    "Type": "Type",
    "Above/Below": "Above/Below",
    "No. of Nights": "No. of Nights",
    "Every": "Every",
    "Status": "Status",
    "stay": "Consecutive Stay",
    "vacancy": "Consecutive Vacancy",
    "above": "Above",
    "below": "Below"
  },
  "ja": {
    "Housekeeping Cycles": "中間清掃設定",
    "Type": "清掃パターン",
    "Above/Below": "以上/以下（指定した数を含む）",
    "No. of Nights": "泊数設定",
    "Every": "清掃間隔",
    "Status": "サイクルの有効設定",
    "stay": "連泊清掃",
    "vacancy": "空室清掃",
    "above": "以上",
    "below": "以下"
  }
}