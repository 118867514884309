{
  "en": {
    "Rate Plans": "Rate Plans",
    "Name": "Name",
    "Code": "Code",
    "Neppan Code": "Neppan Code",
  },
  "ja": {
    "Rate Plans": "Rate Plans",
    "Name": "プラン名",
    "Code": "Code",
    "Neppan Code": "Neppan Code",
  }
}