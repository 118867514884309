<template>
  <div>
    <a-select
      v-model:value="timezone"
      :filter-option="filterOption"
      :size="size"
      show-search
      @change="handleChange"
    >
      <a-select-option
        v-for="c in timezones"
        :key="c"
        :value="c"
        :data="c"
      >
        {{ c }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import timezones from './timezones';

export default {
  name: 'InputTimezone',
  props: {
    valueModel: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    labelKey: {
      type: String,
      default() {
        return 'timezone';
      },
    },
  },
  emits: ['change', 'update:valueModel'],
  data() {
    return {
      timezones,
      timezone: '',
    };
  },
  watch: {
    valueModel: {
      immediate: true,
      handler(nv) {
        this.timezone = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.data.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.timezone);
      this.$emit('update:valueModel', this.timezone.toString());
    },
  },
};
</script>

<style scoped></style>
