<template>
  <a-form-item :label="$t('Category')" name="facilities" class="mb-0">
    <a-select
      v-model:value="category"
      :placeholder="$t('Select facility category')"
      style="width: 100%"
      class="mb-2"
      :size="size"
      show-search
      @change="handleCategoryChange"
      @search="delayCategorySearch"
    >
      <a-select-option v-for="d in categories" :key="d.category">
        {{ d.category }}
      </a-select-option>
    </a-select>
  </a-form-item>
  <a-form-item :label="$t('Sub Category')" name="facilities" class="mb-0">
    <a-select
      v-model:value="subCategory"
      :placeholder="$t('Select facility subcategory')"
      style="width: 100%"
      class="mb-2"
      :size="size"
      show-search
      @change="handleSubCategoryChange"
      @search="delaySubCategorySearch"
    >
      <a-select-option v-for="d in subCategories" :key="d.subCategory">
        {{ d.subCategory }}
      </a-select-option>
    </a-select>
  </a-form-item>
  <a-form-item :label="$t('Select facility')" name="facilities" class="mb-0">
    <a-select
      v-model:value="facilities"
      :placeholder="$t('Select facility')"
      style="width: 100%"
      :size="size"
      mode="multiple"
      @change="handleChange"
    >
      <a-select-option v-for="d in data" :key="d">
        {{ d.name }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<i18n>
{
  "en": {
    "Select facility": "Select facility",
    "Category": "Category",
    "Sub Category": "Sub Category",
    "Select facility category": "Select facility category",
    "Select facility subcategory": "Select facility subcategory"
  },
  "ja": {
    "Select facility": "設備選択",
    "Category": "大区分",
    "Sub Category": "小区分",
    "Select facility category": "大区分選択",
    "Select facility subcategory": "小区分選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputFacilityGroup',
  props: {
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    propertyId: {
      type: String,
      default: undefined,
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      categories: [],
      subCategories: [],
      facilities: [],
      category: undefined,
      subCategory: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.id = nv;
      },
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories(value = '') {
      this.$store.dispatch('facilities/getCategory', {
        propertyId: this.propertyId,
        category: value,
      }).then((data) => {
        this.categories = data.list;
      });
    },
    getSubCategories(value = '') {
      this.$store.dispatch('facilities/getSubCategory', {
        propertyId: this.propertyId,
        category: this.category,
        subCategory: value,
      }).then((data) => {
        this.subCategories = data.list;
      });
    },
    handleCategoryChange() {
      this.subCategory = undefined;
      this.getSubCategories();
    },
    handleSubCategoryChange() {
      this.$store.dispatch('facilities/list', {
        category: this.category,
        subCategory: this.subCategory,
        propertyId: this.propertyId,
      }).then((data) => {
        this.data = data.list;
      });
    },
    handleChange(value) {
      this.$emit('change', value);
      this.$emit('update:value', value);
    },
    delayCategorySearch: debounce(function (value) {
      this.getCategories(value);
    }, 600),
    delaySubCategorySearch: debounce(function (value) {
      this.getSubCategories(value);
    }, 600),
  },
};
</script>

<style scoped></style>
