<template>
  <a-select
    v-model:value="facility"
    show-search
    :placeholder="$t('Select facility')"
    style="width: 100%"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="null"
    :size="size"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="fac in facilities" :key="fac.id" :value="fac.id">
      {{ fac.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select facility": "Select facility"
  },
  "ja": {
    "Select facility": "設備選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputFacility',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    partnerId: {
      type: String,
      default() {
        return undefined;
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      facility: '',
      facilities: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.facility = nv;
      },
    },
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = { pageSize: 100 };

      if (value.length > 0) {
        params.name = value;
      }

      return this.$store.dispatch('facilities/list', params).then((res) => {
        this.facilities = res.list;

        return Promise.resolve();
      });
    },
    handleChange() {
      this.$emit('change', this.facility);
      this.$emit('update:value', this.facility);
    },
    delaySearch: debounce(function (value) {
      this.handleSearch(value);
    }, 600),
  },
};
</script>
