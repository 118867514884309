export const OPERATIONAL_STATUS = [
  { label: 'In operation', value: 'in operation' },
  { label: 'Non-operation', value: 'non-operation' },
  { label: 'Unused', value: 'unused' },
  { label: 'Closed', value: 'closed' },
];

export const CONTRACT_STATUS = [
  { label: 'Contract planned', value: 'contract planned' },
  { label: 'Contracted', value: 'contracted' },
  { label: 'Unused', value: 'unused' },
  { label: 'Cancelled', value: 'cancelled' },
];

export const CONTRACT_TYPE = [
  { label: 'MC+FC', value: 'MC+FC' },
  { label: 'MC NonBrand', value: 'MC NonBrand' },
  { label: 'Owned by the company', value: 'owned by the company' },
  { label: 'Partial operation agency', value: 'partial operation agency' },
  { label: 'Local government project', value: 'local government project' },
];

export const CONSTRUCTION_TYPE = [
  { lable: 'Renovation', value: 'renovation' },
  { lable: 'New construction', value: 'new construction' },
  { lable: 'Existing construction', value: 'existing construction' },
];

export default {
  OPERATIONAL_STATUS,
  CONTRACT_STATUS,
  CONTRACT_TYPE,
  CONSTRUCTION_TYPE,
};
